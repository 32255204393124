//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ElInputWithFrame',
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            default: ''
        },
        errorLabelInsideInput: {
            type: Boolean,
            default: false
        },
        placeholderOverride: {
            type: String,
            default: ''
        },
        isPlaceholderAvailable: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        id () {
            return 'input-with-frame-' + this._uid
        },
        frameMessage () {
            return this.isError
                ? this.error
                : (this.isPlaceholder && this.isPlaceholderAvailable ? (this.placeholderOverride ? this.placeholderOverride : this.$attrs.placeholder) : '')
        },
        frameMessageTooltip () {
            return this.error ? this.frameMessage : ''
        },
        isError () {
            return !!this.error
        },
        isPlaceholder () {
            return !this.isError && this.value
        }
    },
    methods: {
        focus () {
            this.$refs.input.focus()
        }
    }
}
