//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ElButtonWait',
    props: {
        isUdimiLoader: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        iconClass: {
            type: String,
            default: 'ud-spin3'
        },
        throttleChangeLabelMs: {
            type: Number,
            default: 500
        }
    },
    data: () => ({
        timer: null,
        widthOriginal: 0,
        allowChangeLabel: false
    }),
    watch: {
        loading (newVal, oldVal) {
            if (oldVal === true && newVal === false) {
                this.$refs.button.style.width = this.widthOriginal + 'px'
                clearTimeout(this.timer)
                this.allowChangeLabel = false
            }
            if (oldVal === false && newVal === true) {
                this.timer = setTimeout(() => { this.allowChangeLabel = true }, this.throttleChangeLabelMs)
            }
        }
    },
    mounted () {
        this.widthOriginal = this.$refs.button.getBoundingClientRect().width
        this.$refs.button.style.width = this.widthOriginal + 'px'
    },
    methods: {
        click (event) {
            this.loading ? event.preventDefault() : this.$emit('click', event)
        }
    }
}
